import {request} from '@/utils/request'

// 店铺背景
export function getAdminStoreBackend() {
  return request('GET','/shop/backend/store/background', {}, true)
}

export function putAdminStoreBackend(data) {
  return request('PUT','/shop/backend/store/background', data, true)
}

export function delAdminStoreBackend() {
  return request('DELETE','/shop/backend/store/background', {}, true)
}


// 店铺banner
export function getAdminStoreBanner() {
  return request('GET','/shop/backend/store/banner', {}, true)
}

export function postAdminStoreBanner(data) {
  return request('POST','/shop/backend/store/banner', data, true)
}

export function putAdminStoreBanner(id, data) {
  return request('PUT','/shop/backend/store/banner/' + id, data, true)
}

export function delAdminStoreBanner(id) {
  return request('DELETE','/shop/backend/store/banner/' + id, {}, true)
}

// 店铺楼层
export function getAdminStoreFloor() {
  return request('GET','/shop/backend/floor', {}, true)
}

export function postAdminStoreFloor(data) {
  return request('POST','/shop/backend/floor',data, true)
}

export function getAdminStoreFloorInfo(id) {
  return request('GET','/shop/backend/floor/' + id, {}, true)
}

export function delAdminStoreFloor(id) {
  return request('DELETE','/shop/backend/floor/' + id, {}, true)
}

export function putAdminStoreFloor(id, data) {
  return request('PUT','/shop/backend/floor/' + id, data, true)
}
