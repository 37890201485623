<template>
    <div class="admin_page_body">
        <div class="admin_page_item pr0">
            <Menu type="2" />

            <div class="data_group mt24">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="isPopupAdd = true">添加banner</el-button>
            </div>

            <div class="data_table mt24">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                    <el-table-column label="类型" min-width="100px">
                        <template>店铺banner图</template>
                    </el-table-column>
                    <el-table-column prop="url" label="缩略图" min-width="180px">
                        <template slot-scope="scope">
                            <el-image style="width: 200px; height: 100px" :src="scope.row.auditStatus === 1 ? imageURL + scope.row.updateBanner : imageURL + scope.row.banner" class="image" fit="contain"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" min-width="100px" align="center">
                        <template slot-scope="scope">
                            <div class="status yellow" v-if="scope.row.auditStatus === 1">待审核</div>
                            <div class="status green" v-if="scope.row.auditStatus === 2">已通过</div>
                            <div class="status red" v-if="scope.row.auditStatus === 3">已拒绝</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="130" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
                            <el-button type="text" @click="delData(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog class="popup_dialog" title="添加banner" :visible.sync="isPopupAdd" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formAdd" :model="formAdd" :rules="rules" label-width="60px" size="small">
                <el-form-item label="排序：" prop="sort" class="half mrb4">
                    <el-input v-model="formAdd.sort" placeholder="请输入排序" class="fullInput"></el-input>
                </el-form-item>
                <el-form-item label="图片：" prop="banner">
                    <el-upload
                            class="avatar-uploader"
                            :action="uploadURL"
                            :show-file-list="false"
                            :before-upload="beforeUploadImage"
                            :on-success="uploadAddSuccess">
                        <img v-if="formAdd.banner" :src="imageURL + formAdd.banner" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__tip">注：该banner图请使用1920*650像素的.jpg、.jpeg、.png图片格式，图片大小不超过10M</div>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitFormAdd('formAdd')" :loading="isSubmitLoading">提  交</el-button>
                    <el-button @click="isPopupAdd = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog class="popup_dialog" title="编辑banner" :visible.sync="isPopupEdit" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formEdit" :model="formEdit" :rules="rules" label-width="60px" size="small">
                <el-form-item label="排序：" prop="sort" class="half mrb4">
                    <el-input v-model="formEdit.sort" placeholder="请输入排序" class="fullInput"></el-input>
                </el-form-item>

                <el-form-item label="图片：" prop="banner">
                    <el-upload
                            class="avatar-uploader"
                            :action="uploadURL"
                            :show-file-list="false"
                            :before-upload="beforeUploadImage"
                            :on-success="uploadEditSuccess">
                        <img v-if="formEdit.banner" :src="imageURL + formEdit.banner" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__tip">注：该背景图请使用1920*650像素的.jpg、.jpeg、.png图片格式，图片大小不超过10M</div>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitFormEdit('formEdit')" :loading="isSubmitLoading">提  交</el-button>
                    <el-button @click="isPopupEdit = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
  import {imageURL, uploadURL} from "../../../../utils/config";
  import {getAdminStoreBanner, postAdminStoreBanner, putAdminStoreBanner, delAdminStoreBanner} from '../../../../api/admin/store';
  import {beforeUploadImage} from '../../../../utils/method'
  const Menu = () => import('../menu');
  export default {
    name: "adminStoreBackend",
    data () {
      return {
        type: '1',
        isRequestLoading: false,
        listData: [],
        rules: {
          banner: [
            {required: true, message: '请上传图片', trigger: 'change'}
          ],
          sort: [
            {required: true, message: '请输入排序', trigger: 'blur'}
          ]
        },
        isPopupAdd: false,
        formAdd: {
          banner: '',
          sort: ''
        },
        imageURL: imageURL,
        uploadURL: uploadURL,
        isPopupEdit: false,
        formEdit: {
          sort: '',
          banner: '',
          id: 0
        },
        isSubmitLoading: false,
        beforeUploadImage
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getList()
    },
    methods: {
      getList () {
        this.isRequestLoading = true;
        getAdminStoreBanner().then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            if (res.data.storeBanner) {
              this.listData = res.data.storeBanner;
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      uploadAddSuccess(res) {
        if (res.code === 0) {
          this.formAdd.banner = res.data;
        }
      },
      uploadEditSuccess(res) {
        if (res.code === 0) {
          this.formEdit.banner = res.data;
        }
      },
      submitFormAdd (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true;
            postAdminStoreBanner(this.formAdd).then(res => {
              this.isSubmitLoading = false;
              if (res.code === 0) {
                this.$refs['formAdd'].resetFields();
                this.isPopupAdd = false;
                this.getList();
                this.$message({
                  message: 'banner已上传，等待后台审核',
                  showClose: true,
                  type: 'success'
                })
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      toEdit (row) {
        this.formEdit.id = row.id
        this.formEdit.sort = row.sort
        if (row.auditStatus === 1) {
          this.formEdit.banner = row.updateBanner
        } else {
          this.formEdit.banner = row.banner
        }
        this.isPopupEdit = true
      },
      submitFormEdit (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true;
            putAdminStoreBanner(this.formEdit.id, {
              banner: this.formEdit.banner,
              sort:  this.formEdit.sort
            }).then(res => {
              this.isSubmitLoading = false;
              if (res.code === 0) {
                this.isPopupEdit = false;
                this.getList();
                this.$message({
                  message: 'banner已上传，等待后台审核',
                  showClose: true,
                  type: 'success'
                })
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      delData (id) {
        this.$confirm('此操作将永久删除该banner图, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delAdminStoreBanner(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'banner图删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      }
    },
    components: {
      Menu
    }
  }
</script>
